.header .logo {
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 170px;
}

.header-wrapper {
  position: sticky;
  top: 0;
  background-color: var(--primary-o);
  backdrop-filter: blur(7px);
  z-index: 11;
}

.logo img {
  width: 100%;
  height: 100px;
}

.header-menu ul li a:hover {
  color: var(--accent);
}

.header-menu ul li a {
  font-size: 1.4rem;
  font-weight: 600;
}

.hero-img {
  width: 130%;
  margin-left: -30%;
}

.footer-menu ul {
  flex-direction: column;
  gap: .2rem;
}

.footerA {
  max-width: 300px;
}

.footerD .logo {
  max-width: 170px;
  margin-bottom: 1rem;
}

.footer h2 {
  color: var(--light);
  margin-bottom: 1rem;
}

.basic-par p{
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.basic-par p strong{
  font-weight: 800;
}

.section-img-reverse {
  display: flex;
  justify-content: flex-end;
}

.section-img {
  position: relative;
}

.section-img img {
  width: 85%;
}

.img-frame {
  content: '';
  display: block;
  border: 20px solid var(--secondary);
  width: 95%;
  height: 85%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.section-text-reverse {
  display: flex;
  justify-content: flex-end;
}

.section-text-inner {
  max-width: calc(var(--content) / 2);
}

.icon-img {
  max-width: 200px;
  height: 200px;
}

.icon-par {
  font-size: 1.3rem;
}

.icon-img-box {
  height: 190px;
}

.list-box {
  flex-wrap: wrap;
  gap: 3rem;
}

.map-container {
  width: 100%;
}

.map-address {
  text-align: center;
  font-size: 1.3rem;
}

.list-item {
  color: var(--light);
  transition: all .3s ease;
}

.list-item::before {
  content: '*';
  margin-right: 5px;
}

.list-item:hover {
  color: var(--secondary);
  transition: all .3s ease;
}

.column-list-item {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.column-list {
  list-style: disc;
}

.offer-list {
  max-width: 800px;
  margin: 0 auto;
}

.single-step-left {
  border-right: 20px solid var(--secondary);
  align-self: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transform: translateX(10px);
  align-items: flex-start;
}

.steps-separator {
  border-right: 20px solid var(--secondary);
  align-self: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transform: translateX(10px);
  height: 90px;
}

.single-step-right .step-id {
  flex-direction: row-reverse;
}

.step-id {
  display: flex;
  align-items: center;
  width: 100%;
}

.step-id-bar {
  width: 100%;
  height: 15px;
  background-color: var(--secondary);
}

.single-step-right {
  border-left: 20px solid var(--secondary);
  align-self: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
  transform: translateX(-10px);
  align-items: flex-end;
}

.step-id-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid var(--secondary);
  border-radius: 50%;
  width: 95px;
  height: 95px;
  min-width: 95px;
  min-height: 95px;
  color: var(--secondary);
  font-size: 3rem;
  font-weight: 800;
}

.step-heading {
  color: var(--light);
  font-size: 2rem;
  font-weight: 700;
}

.step-desc {
  max-width: 80%;
  font-size: 1.2rem;
}

.contact-page .address-heading {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.contact-page .address-par {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.contact-page .address-phone {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--secondary);
}

.contact-page {
  text-align: right;
  max-width: 400px;
}

.contact-form {
  max-width: 400px;
}

.colors-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.single-color {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 4px solid var(--light);
  border-radius: 50%;
}

.edit-content {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50vh;
  background-color: azure;
  color: black;
}

/* responsive ------------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 1200px) {
  .hero-left {
    text-align: center;
  }

  .m-column-reverse {
    flex-direction: column-reverse;
  }

  .w100, .w20, .w25, .w40, .w50, .w60, .w75, .w80 {
    width: 100%;
  }

  .list-item {
   text-align: center !important;
  }

  .hero-img {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }

  .footerA, .footerB, .footerC, .footerD, .footer-bar {
    text-align: center;
    width: 100%;
    max-width: 100%;
  } 
}