/* layout ---------------------------------------- */

body {
  background-color: var(--primary);
  color: var(--light);
  
  font-family: var(--mainFont);
  background-image: url('https://sabio.com.pl/assets/bg.png');
  background-repeat: no-repeat;
  background-size: 170%;
  background-position: 120% 105%;
}

.box {
  max-width: var(--content);
  width: 100%;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.column-reverse {
  flex-direction: column-reverse;
}

.row-reverse {
  flex-direction: row-reverse;
}

.j-center {
  justify-content: center;
}

.j-start {
  justify-content: flex-start;
}

.j-end {
  justify-content: flex-end;
}

.j-beetween {
  justify-content: space-between;
}

.a-center {
  align-items: center;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}

.g1 {
  gap: 1rem;
}

.g2 {
  gap: 2rem;
}

.g3 {
  gap: 3rem;
}

.g4 {
  gap: 4rem;
}

.g5 {
  gap: 5rem;
}

.w100 {
  width: 100%;
  flex-basis: 100%;
}

.w80 {
  width: 80%;
}

.w75 {
  width: 75%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* margin & padding ------------------------------------------- */

.m-auto {
  margin: 0 auto;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

/* styling ---------------------------------------------------- */

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-dark {
  background-color: var(--dark);
}

.bg-light {
  background-color: var(--light);
}

.bg-blur {
  backdrop-filter: blur(5px);
}

/* navigation ----------------------------------------------- */

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-menu .navigation-list {
  flex-direction: column;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hamburger {
  display: none;
}



/* responsive ------------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 1200px) {
  .m-column {
    flex-direction: column;
  }

  .main {
    padding: 0 1.5rem;
  }

  .hamburger {
    display: block;
  }

  .desktop {
    display: none;
  }
}