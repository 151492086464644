.modal-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 95%;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 700;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.btn {
  cursor: pointer;
  background-color: var(--primary);
  color: var(--light);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  border: 5px solid var(--secondary);
  transition: all .3s ease;

  &:hover {
    background-color: var(--secondary);
    color: var(--light);
    transition: all .3s ease;
  }
}

.btn-cta {
  cursor: pointer;
  background-color: var(--accent);
  color: var(--light);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  border: 5px solid var(--accent);
  transition: all .3s ease;

  &:hover {
    background-color: var(--primary);
    color: var(--light);
    transition: all .3s ease;
  }
}

.btn-alert {
  cursor: pointer;
  background-color: red;
  color: var(--light);
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 700;
  border: 5px solid red;
  transition: all .3s ease;

  &:hover {
    background-color: var(--primary);
    color: var(--light);
    transition: all .3s ease;
  }
}

