@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Philosopher:ital,wght@0,400;1,700&display=swap');

h1, h2, h3, h4, h5 {
  color: var(--secondary);
}

a:hover {
  color: var(--accent);
}

.s1 {
  font-size: 1rem;
}

.s1_5 {
  font-size: 1.5rem;
}

.s2 {
  font-size: 2rem;
}

.s2_5 {
  font-size: 2.5rem;
}

.s3 {
  font-size: 3rem;
}

.s3_5 {
  font-size: 3.5rem;
}

.s4 {
  font-size: 4rem;
}

.s5 {
  font-size: 5rem;
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.light {
  color: var(--light);
}

.dark {
  color: var(--dark);
}

.t-center {
  text-align: center;
}

.t-m-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.italic-font {
  font-family: var(--mainFont);
  font-style: italic;
}

.alt-font {
  font-family: var(--alternate);
}

.w-100 {
  font-weight: 100;
}

.w-200 {
  font-weight: 200;
}

.w-300 {
  font-weight: 300;
}

.w-400 {
  font-weight: 400;
}

.w-500 {
  font-weight: 500;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700;
}

.w-800 {
  font-weight: 800;
}

.w-900 {
  font-weight: 900;
}

/* responsive ------------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 1200px) {
  .s4 {
    font-size: 3rem;
  }

  .s3 {
    font-size: 2rem;
  }
}