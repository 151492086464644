@import url('./css/layout.css');
@import url('./css/typo.css');
@import url('./css/elements.css');
@import url('./css/components.css');

/* variables ----------------------------------------------------------------------------------------*/
:root {
  --primary: rgb(49, 49, 49);
  --primary-o: rgba(49, 49, 49, 0.74);
  --secondary: rgb(255, 20, 0);
  --accent: #E84300;
  --light: rgb(255, 255, 255);
  --dark: rgb(0, 0, 0);
  --mainFont: 'Montserrat', sans-serif;
  --alternate: 'Philosopher', sans-serif;
  --content: 1500px
}

/* reset ------------------------------------------------------------------------------------------------ */

html {
  box-sizing: border-box;
  font-size: 14px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--accent);
  text-decoration: none;
}

a:active {
  color: inherit;
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

